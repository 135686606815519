import Vue from 'vue'
import { IAppModule, ILink, IView } from '@/common/types'
import { Access } from '@/common/enums'

export default class AdminModule implements IAppModule {
  async init(): Promise<any> {
    Vue.component('UserDialog', (await import('./views/UserDialog.vue')).default);
    Vue.component('RoleDialog', (await import('./views/RoleDialog.vue')).default);
    Vue.component('TranslateDialog', (await import('./views/TranslateDialog.vue')).default);
    Vue.component('CompanyDialog', (await import('./views/CompanyDialog.vue')).default);
    Vue.component('ClinicDialog', (await import('./views/ClinicDialog.vue')).default);
    Vue.component('CompanyServiceDialog', (await import('./views/CompanyServiceDialog.vue')).default);
  }

  async routes(): Promise<Array<ILink>> {
    return [

      { name: 'UsersView', path: '/security/users', component: (await import('./views/UsersView.vue')).default, access: Access.SecurityManager },
      { name: 'RolesView', path: '/security/roles', component: (await import('./views/RolesView.vue')).default, access: Access.SecurityManager },
      { name: 'TranslatesView', path: '/system/translates', component: (await import('./views/TranslatesView.vue')).default, access: Access.SystemAdministrator },
      { name: 'CompanyView', path: '/system/companies', component: (await import('./views/CompanyView.vue')).default, access: Access.SystemAdministrator }

    ]
  }

  async userMenu(): Promise<Array<IView>> {
    return [
    ]
  }

  async reports(): Promise<Array<IView>> {
    return [
    ]
  }
}
